body {
  overflow-x: hidden;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='4000' preserveAspectRatio='none' viewBox='0 0 1440 4000'%3e%3cg mask='url(%26quot%3b%23SvgjsMask3108%26quot%3b)' fill='none'%3e%3crect width='1440' height='4000' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient3109%26quot%3b)'%3e%3c/rect%3e%3cpath d='M1440 0L915.12 0L1440 1871.55z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M915.12 0L1440 1871.55L1440 2841.71L570.14 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M570.14 0L1440 2841.71L1440 3353.63L345.57 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M345.56999999999994 0L1440 3353.63L1440 3780.53L111.48999999999992 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 4000L96.98 4000L0 2291.79z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 2291.79L96.98 4000L778.75 4000L0 1234.54z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 1234.54L778.75 4000L915.23 4000L0 747.8599999999999z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 747.8600000000001L915.23 4000L1151.95 4000L0 571.2900000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask3108'%3e%3crect width='1440' height='4000' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='-44.44%25' y1='16%25' x2='144.44%25' y2='84%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient3109'%3e%3cstop stop-color='rgba(148%2c 48%2c 56%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.12'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.87'%3e%3c/stop%3e%3cstop stop-color='rgba(193%2c 18%2c 31%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  color: #ffffff !important;
  background-size: cover;
}
.App {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}