.footer-user{
    background-color: #c1121f;
    margin-top: 10%;
    color: white;
    display: flex;
    position: relative;
    width: 100%;
    bottom: 0;
}

.logo-container1 {
    border-radius: 10px;
    background-color: black; /* Black background color */
}

.section-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
    margin: 0 auto;
    padding: 16px;
    min-height: 160px;
}

.section-3 h4 {
    align-items: center;
    justify-content: center;
    display: flex;
}

.section-3 li a{
    color: aliceblue !important;
}


h4 {
    text-align: left;
    color: #d4d0e6;
    margin-top: 0;
    margin-bottom: 16px;
    opacity: 0.7;
    font-size: 16px;
    font-weight: 600;
}

menu {
    list-style-type: none;
}

menu li {
    margin-bottom: 16px;
}

.logo-footer-user{
    width: 180px;
    margin-bottom: 1px;
    padding-right: 1%;
}

.footer-social-icon{
    display: block;
    padding-right: 1%;
}

.footer-social-icon img {
    width: 30px;
    height: 30px;
}

.footer-bottom-user{
    height: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1px;
}


@media screen and (max-width: 767px) {
    .section-1 {
      flex-direction: column;
      align-items: center;
    }
    .section-3{
        height: 40px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px;
    }
    
  }