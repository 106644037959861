.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slider {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slider-content {
  position: relative;
}

.slider img {
  width: 100%;
  height: auto;
  max-height: 100vh;
  cursor: grab;
}

.btn.prev-btn,
.btn.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
}

.btn.prev-btn {
  left: 10px;
}

.btn.next-btn {
  right: 10px;
}

.slide-buttons {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #bbb;
  cursor: pointer;
}

.dot.active {
  background-color: #ff0000;
}

@media screen and (max-width: 850px) {
  .slider-container {
    width: 100%;
    margin-top: 20%;
  }
}
