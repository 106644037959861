* {
  margin: 0;
  box-sizing: border-box;
}

.section {
  background: url("../../assets/img/camara.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.page {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
}

.cover {
  background: transparent;
  width: 55vh;
  height: 65vh;
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.55em #c1121f;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 15%;
  margin-left: 110vh;
  border: 2px solid rgba(6, 6, 6, 0.5);
  padding: -5vh;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}
.cover h1 {
  color: #ffffff;
}

input {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  background-color: transparent;
  font-size: 1.2rem;
  color: #ffffff !important;
  margin: 0 auto;
}

input::placeholder {
  color: white;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 30ch;
  background-color: #0a0a0a;
  color: aliceblue;
}

.login-btn:hover {
  background-color: rgb(50, 51, 50);
  color: #ffffff;
}

.new-user {
  color: white;
  text-decoration: none;
}

.new-user:hover {
  color: rgb(253, 253, 255) !important;
}

/* Estilos para tablet */
@media only screen and (max-width: 768px) {
  .page {
    font-size: 0.65em;
  }

  .cover {
    width: 40vh;
    height: 40vh;
    margin-left: 0;
    margin-top: 50%;
  }

  input {
    width: 30vh;
  }

  .login-btn {
    width: 15ch;
  }
}

/* Estilos para móvil */
@media only screen and (max-width: 915) {
  /* falta poner el px */
  .page {
    font-size: 0.5em;
  }

  .cover {
    width: 30vh;
    height: 30vh;
    margin-top: 30%;
  }

  input {
    width: 20vh;
  }

  .login-btn {
    width: 10ch;
  }
}
