.nav-menu {
    display: flex;
    align-items: center; /* Alinear verticalmente al centro si es necesario */
    list-style: none;
    margin: 0;
    padding: 0;
    background:#c1121f; 
    font-family: Arial, Helvetica, sans-serif; 
  }

  .logo-container-admin{
    position: absolute;
  }
    