/*ESTILOS FORGOTPASSWORDINPUT*/
.container-password{
    padding-top: 20%;
}

.Container-changePass{
    padding-top: 20%;
    
}
.Container-changePass h1{
    padding-bottom: 4rem;
}
.Container-changePass .recuperar-contraseña-input{
    margin-bottom: 1em;

}
.submit-button-forgot {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #ff0000 !important;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .submit-button-forgot:hover {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #17e72f !important;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in 0.2s;
  }

  .recuperar-contraseña-input{

  }


@media screen and (max-width: 767px) {
    .container-password{
        padding-top: 50%;
    }
}