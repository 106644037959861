.NavbarItemsUser{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  height: 85px;
  border-radius: 0px;
  background:#c1121f;  
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
  font-family: Arial, Helvetica, sans-serif;
}
.logo-container-user{
  position: absolute;
}

/* Wsp buton */ 

.logo-container2 {
  position: relative;
  top: 0;
  left: 0;
  width: 60px; /* Adjust the width */
  height: 60px; /* Adjust the height */
  display: flex;
  padding-top: 50%;
  z-index: 1;
}

.btn-whatsapp {
  position: relative; /* Use absolute positioning */
  bottom: 10px; /* Adjust the distance from the bottom */
  right: 10px; /* Adjust the distance from the right */
}

.btn-whatsapp > img{
  width: 3em;
  position: relative;
  z-index: 9999;

}

.btn-whatsapp::before,
.btn-whatsapp::after{
  content: "";
  position: absolute;
  top: 2px;
  left: 1px;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: #0bbb66;
  opacity: 0;
  animation: onda 1.7s infinite;
}
.btn-whatsapp:before{
  animation-delay: 1s;
}
.btn-whatsapp:after{
  animation-delay: 1.3s;
}

@keyframes onda {
  0% {
    transform: scale(1);
  }
  15% {
    opacity: 1;
  }

  100%{
    opacity: 0;
    transform: scale(2.5);
  }
  
}

@media screen and (max-width: 850px){
    .NavbarItemsUser{
      z-index: 99;
    }

    .logo-container2{
      padding-top: 150%;
      z-index: 99999;
    }
}


