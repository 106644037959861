.gallery-container {
  margin-top: 30px;
}

.photo-gallery-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.photo-item {
  width: calc(20% - 10px); /* 5 fotos por fila */
  height: auto;
  cursor: pointer;
}

.photo-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
}


@media (max-width: 767px) {
  .photo-item {
    width: 100%; /* 1 foto por fila en dispositivos móviles */
  }
}
