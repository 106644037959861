.container-photos{
    text-align: center; /* Centrar el contenido dentro del container */
}
.container-photos h3{
    align-items: center;
    margin-top: 10%;
}

.text-search-bar{
    position: relative;
    z-index: 2;
}


@media screen and (max-width: 850px){
    .container-photos{
        margin-top: 50%;
    }
}