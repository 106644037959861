.container-evento{
  
    margin-top: 100px;
}

.box-evento {
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
    background-color: #f7f7f7;
  }
  
  .logo-evento {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .name-evento {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .date-evento {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
  }
  
  .search-evento {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
  }


  .load-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  