ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .text-info{
    color: rgb(255, 255, 255) !important;
    background-color: #c1121f;
    font-size: 22px;
    font-weight: 400;
  }

@media screen and (max-width: 767px) {
  .text-info{
    color: rgb(255, 255, 255) !important;
    background-color: #c1121f;
    font-size: 15px;
    font-weight: 400;

  }
}