.user-menu {
    display: flex;
    flex-direction: column;
  }
  
  .user-menu-item {
    padding: 10px;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s;
    background-color: #2c2c2c;
  }
  
  .user-menu-item:hover {
    background-color: #f2f2f2;
  }
  
  .logout-link {
    color: red;
  }