.container-aviso{
    padding-top: 10%;
    color: rgb(255, 255, 255);
}
.somos-naiken{
    color: aliceblue !important;
    margin-bottom: 10%;
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: #c1121f;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
}
.solicitud-fotografia{
    color: rgba(240, 248, 255, 0.834) !important;
    margin-bottom: 10%;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: #c1121f;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    font-size: 20px;
    margin-bottom: 40px;
    display: inline-block;
    transition: transform 0.3s ease;
}

.solicitud-fotografia:hover{
    transform: scale(1.1);

}
.solicitud-fotografia h3{
    font-size: 25px;
    font-weight: 100;
}

.solicitud-fotografia-link link{
    color: rgb(206, 206, 206) !important;
}

.solicitud-fotografia a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none;
}