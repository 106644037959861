/* Hoja de estilos Evento.css */
.event-card {
  color: rgb(1, 1, 1) !important;
  width: 100%;
  max-width: 100%;
  height: 350px; /* Tamaño fijo para la tarjeta */
  background-color: rgb(255, 255, 255) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1rem 0 1rem;
  overflow: hidden; /* Evita desbordamiento de contenido */
}

.card-title {
  font-size: 1.2rem; /* Cambia el tamaño del título según tus preferencias */
  max-height: 1.5rem; /* Altura máxima del título */
  overflow: hidden; /* Evita desbordamiento del título */
}

.card .btn-buscar-fotos {
  background-color: black;
  color: white;
  width: 100%;
}

.card .btn-buscar-fotos:hover {
  background-color: #333;
}

.event-logo {
  width: 100%;
  max-height: 100px;
  object-fit: contain;
  min-height: 100px;
}
