.container2 {
  color: #000000 !important;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

h1 {
  text-align: center;
}

.nombre-form{
  color: black !important;
}
.apellido-form{
  color: black !important;
}
.telefono-form{
  color: black !important;
}

.container2 .email-form{
  color: black !important;
}
.container2 .password-form{
  color: black !important;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input.email-form {
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  color: #000000;
  width: 100%;
}

button[type="submit"] {
  padding: 10px;
  background-color: #070707;
  color: #ffffff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: transform 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #10c81f;
  transform: scale(1.1);
}

.forgot-password-btn {
  margin-top: 10px;
  background-color: transparent;
  border: none;
  color: #fefeff;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password-btn:hover {
  color: #ffffff;
}

.text-info1{
  color: rgb(35, 137, 233);
}
