.table-container {
  width: 100%; /* Ancho deseado para la tabla */
  margin-top: 5%;
  color: black !important;
}
.user-list-container {
  color: #c1121f;
  margin: 20px 0; /* Cambiar el margen superior e inferior para ajustar el contenido hacia arriba */
  max-width: 80%;
  margin-right: 40%; /* Alinea el contenedor hacia la izquierda */
}
.user-list-container h2 {
  text-align: center;
  margin-top: 5%;
}

.search-bar {
  margin-top: 10px; /* Reduzca el margen superior */
  justify-content: space-between;
  margin-bottom: 10px;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #fd0000;
  border-radius: 4px;
  font-size: 18px;
}

.user-table {
  width: 100%; /* Ocupa el ancho completo del contenedor */
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  font-size: 18px;
}

.table-header {
  color: #ffffff;
  padding: 10px;
  font-weight: 700px;
  text-align: left;
  background-color: #c1121f;
  text-align: center;
}

.user-table td {
  padding: 10px;
  border-bottom: 1px solid #c1121f;
}

.user-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.user-table tbody tr:hover {
  background-color: #f2f2f2;
}
