.logo-container {
    position: relative;
}

.logo-background {
    position: absolute;
    top: -1em; /* Alinea el círculo con la parte superior del .logo-container */
    left: 2.5em;
    transform: translateX(-50%);
    width: 6.5em; /* Ajusta el tamaño del círculo según tus necesidades */
    height: 6.5em; /* Ajusta el tamaño del círculo según tus necesidades */
    border-radius: 100%;
    background-color: #000000; /* Color de fondo blanco */
    z-index: -1; /* Establece un valor menor que el z-index del .NavbarItems */
}

.logo .image-logo {
    position: relative;
    padding-top: 0.5em;
    width: 5em;
    float: left;
}

.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 100px;
    border-radius: 0px;
    background:#c1121f;  
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    font-family: Arial, Helvetica, sans-serif;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    padding: 0.5rem 1rem;
    margin-top: 0.9rem;
    align-items: center
}

.nav-links{
    text-decoration: none;
    color: #fffefe;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    display: flex;
    transition: transform 0.3s ease;
}

.nav-links i{
    padding-right: 4.5px;
}

.nav-links:hover{
    background-color: #ffffff;
    color: #020202;
    border-radius: 4px;
    transform: scale(1.1);


}

.nav-links-mobile{
    display: none;
}

.button-sesion button{
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    font-size: 18px;
    font-weight: 700;
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    position: relative;
    background: transparent;
    color: #fff;
    transition: transform 0.3s ease;
}

.button-sesion button:hover{
    background-color: #fffafa;
    color: #020202;
    transform: scale(1.1);
    border: 1px solid #000000;
}

.menu-icons{
    display: none;
}

@media screen and (max-width: 850px) {
    .NavbarItems{
        z-index: 99;
    }

    .nav-menu {
        color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #000000;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;    
    } 

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
        color: #ffffff;

    }
    .nav-links:hover{
        background: #ec0000;
        transition: none;
        color: #fffafa;
    }

    button{
        display: none;
    }
    /* Boton Iniciar Sesion */
    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: #0d0d0d;
        text-decoration: none;
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }
    .menu-icons{
        display: block;
        cursor: pointer;
    }
    /* Burger Bottom */
    .menu-icons i{
        font-size: 1.2rem;
        color: #ffffff;
    }
}