.container-help{
    margin-top: 10%;
    margin-bottom: 10%;
}

.container-help h1 {
    padding-bottom: 5%;
}
.container-help p{
    margin-bottom: 5%;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

@media screen and (max-width: 878px) {
    .container-help{
        margin-top: 40%;
    }

    .container-help h1 {
        padding-bottom: 10%;
    }
    .container-help p{
        margin-bottom: 10%;
        font-size: 20px;
    
    }
    
}