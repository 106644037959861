.container-cargar-fotos {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alineación en la parte superior */
  height: 120vh;
  width: 100%;
  padding-right: 60%;
}

.carry-box {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 600px; /* Ajusta el ancho del formulario */
  max-width: 600px; /* Establece un ancho máximo */
  margin-top: 50px; /* Ajusta el espacio desde la parte superior */
  margin-bottom: 50px; /* Ajusta el espacio desde la parte inferior */
  margin-left: 0; /* Alinea el formulario a la izquierda */
  margin-right: auto; /* Centra horizontalmente */
}

.carry-box h2 {
  text-align: center;
  margin-bottom: 10px;
  color: black !important;
}

.carry-box .form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.carry-box .btn-primary {
  background-color: #c8c6c6;
  color: #090909 !important;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
}

.carry-box .btn-primary:hover {
  background-color: #2fca499f;
}

.name-evento {
  background-color: #c1121f;
}

.form-control-input .form-control {
  color: #040404 !important;
}
.form-control {
  color: #040404 !important;
}
.form-control-text {
  background-color: rgb(160, 156, 156);
  color: #040404;
}
.form-control-text::placeholder {
  color: #040404;
}

@media (max-width: 767px) {
  .carry-box {
    width: 100%;
    padding: 10px;
  }
}

.loading-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db; /* Color del spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
